@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
/* ==========================================================================
   Responsive images and box padding
   ========================================================================== */

img,
object {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}
* {
  -moz-box-sizing: border-box;
  -webkit-kit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 100%;
  background: #fefefe;
  font-family: NunitoSans, Arial, serif, Helvetica, sans-serif;
}

.container {
  width: 80%;
  margin: 60px auto;
}

/* Hide arrow input type number - start */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: NunitoSans;
  src: url(/static/media/NunitoSans-Regular.87d92665.ttf);
}

.title-admin {
  color: #B1C2D8 !important;
  font-weight: normal !important;
  font-size: 1.8rem !important;
  margin-top: 1.5rem !important;
  margin-bottom: .3rem !important;
}

/* Hide arrow input type number - end */
.container {
  text-align: center;
}
.toggle-switch {
  position: relative;
  width: 80px;
  display: inline-block;
  text-align: left;
  top: 8px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "Show";
  padding-left: 10px;
  background-color: #29D09E;
  color: #fff;
}
.inner:after {
  content: "Hide";
  padding-right: 10px;
  background-color: #F45A5A;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 20px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}
